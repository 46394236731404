import React, { useEffect, useState } from "react";
import HabitCard from "../pieces/HabitCard";
import CustomField from "../pieces/CustomField";

export default function SavedHabits({ habits }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredHabits, setFilteredHabits] = useState([]);

  console.log(habits)
  const reversedHabits = habits.slice().reverse();

  useEffect(() => {
    console.log(habits);
    filterHabits();
  }, [habits, searchTerm]);

  const filterHabits = () => {
    if (!searchTerm) {
      setFilteredHabits(reversedHabits);
    } else {
      const filtered = reversedHabits.filter((habit) =>
        habit.trigger.toLowerCase().includes(searchTerm.toLowerCase()) + habit.behavior.toLowerCase().includes(searchTerm.toLowerCase()) + habit.result.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredHabits(filtered);
    }
  };

  return (
    <div style={{ paddingBottom: "40px" }}>
      {!habits.length && (
        <span>
          <i>You don't have any habits stored yet!</i>
        </span>
      )}
      <CustomField
        label={"Filter"}
        color={"aliceblue"}
        onChange={setSearchTerm}
        value={searchTerm}
        className={"habit-fields"}
      />
      {filteredHabits.map((habit, index) => (
        <HabitCard
          trigger={habit.trigger}
          behavior={habit.behavior}
          result={habit.result}
          date={habit.date}
          index={index}
          key={index}
          id={habit.$id}
        />
      ))}
    </div>
  );
}
