import '../App.css';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom"; 
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

// import { outlinedInputClasses } from '@mui/material/OutlinedInput';
// import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import Header from '../components/larger/Header';

// import IconButton from '@mui/material/IconButton';
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { CircularProgress, Stack, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomField from '../components/pieces/CustomField';
import CustomButton from '../components/pieces/CustomButton';
import SavedHabits from '../components/larger/SavedHabits';
import useLocalStorage from '../components/utils/useLocalStorage';

import { Client, Account, Databases, ID } from 'appwrite';
const client = new Client();
client
    .setEndpoint('https://sm-b1.smorin.app/v1')
    .setProject('660820ca455a28164017');
const account = new Account(client);
const databases = new Databases(client);

export default function Main() {
//   const outerTheme = useTheme();
  const navigate = useNavigate();
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [trigger, setTrigger] = useState();
  const [behavior, setBehavior] = useState();
  const [habitResult, setHabitResult] = useState();
  const [savingLoop, setSavingLoop] = useState(false);
  const [savedLoopSuccess, setSavedLoopSuccess] = useState();
  const [habits, setHabits] = useState([]);
  const [loopVis, setLoopVis] = useLocalStorage('loopVis', false)

  useEffect(() => {
    const getHabits = async () => {
        try {
          const result = await databases.listDocuments(
            'awp-webdb1', // databaseId
            'sam-habit-loops', // collectionId
          );
          console.log(result);
          setHabits(result.documents);
        } catch (error) {
          console.log(error.message);
        }
      };

    getHabits();
  },[])

  const handleClose = () => {
    setErrorOpen(false);
  }

  const handleLogout = async () => {
    try {
      const result = await account.deleteSession('current');
      console.log(result)
    } catch (error) {
      console.log(error.message)
    } finally {
      window.location.href = '/'
    }
  }

  const handleSaveNewLoop = async () => {
    setSavingLoop(true);

    if (behavior === '') {
        setErrorMessage('Invalid document structure: Missing required attribute "behavior"')
        setErrorOpen(true)
        setTimeout(() => setErrorOpen(false),5000)
    } else if (habitResult === '') {
        setErrorMessage('Invalid document structure: Missing required attribute "result"')
        setErrorOpen(true)
        setTimeout(() => setErrorOpen(false),5000)
    }

    const data = {
      trigger: trigger,
      behavior: behavior,
      result: habitResult,
      date: new Date()
    };
    
    try {
        const result = await databases.createDocument(
            'awp-webdb1', // databaseId
            'sam-habit-loops', // collectionId
            ID.unique(), // documentId
            data,
          );
        console.log(result)
        if (result) {
            setSavedLoopSuccess(true)
            setTimeout(() => setSavedLoopSuccess(),5000)
            getHabits();
        }
        setTrigger('');
        setBehavior('');
        setHabitResult('');
    } catch (error) {
        console.log(error.message)
        setErrorMessage(error.message)
        setErrorOpen(true);
        setTimeout(() => setErrorOpen(false),5000)
    } finally {
        setSavingLoop(false)
        setTrigger('');
        setBehavior('');
        setHabitResult('');
        document.getElementById('trigger-field').focus()
    }
  }

  const getHabits = async () => {
    try {
      const result = await databases.listDocuments(
        'awp-webdb1', // databaseId
        'sam-habit-loops', // collectionId
      );
      console.log(result.documents);
      setHabits(result.documents);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleKeyPress = () => {
    handleSaveNewLoop();
  };

  const toggleLoopVis = () => {
    if (loopVis) {
      setLoopVis(false)
      document.getElementById('main-div').style.alignItems = 'center'
      // document.getElementById('loops').style.display = 'none'
    } else {
      setLoopVis(true)
      document.getElementById('main-div').style.alignItems = ''
      // document.getElementById('loops').style.display = 'block'
    }
  }


  useEffect(() => {
    if (!loopVis) {
      setLoopVis(false)
      document.getElementById('main-div').style.alignItems = 'center'
      // document.getElementById('loops').style.display = 'none'
    } else {
      setLoopVis(true)
      document.getElementById('main-div').style.alignItems = ''
      // document.getElementById('loops').style.display = 'block'
    }
  },[])

  return (
    <>
    <div className="App" id="main-div" style={{ display: 'flex', justifyContent: 'center', height: '100vh', width: "100vw" }}>
      <Box sx={{ width: "100vw" }}>
        <Header />
          <div style={{padding: "10px"}}>
            <CustomField
              id={'trigger-field'}
              value={trigger}
              onChange={setTrigger}
              label={"Trigger"}
              color={"aliceblue"}
              className={"habit-fields"}
              onEnter={handleKeyPress}
            />
            {/* <Tooltip title="The TRIGGER is what starts the habit. It can be something you see or a place you visit, or just a thought, emotion, or physical sensation.">
                <HelpOutlineIcon style={{ color: 'aliceblue' }} />
            </Tooltip> */}
          </div>
          <div style={{padding: "10px"}}>
            <CustomField
              value={behavior}
              onChange={setBehavior}
              label={"Behavior"}
              color={"aliceblue"}
              className={"habit-fields"}
              onEnter={handleKeyPress}
            />
            {/* <Tooltip title="The BEHAVIOR is the habit
                            itself. It could be a physical
                            behavior like biting your nails
                            or too much time on social
                            media. It can also be a mental
                            behavior like worrying or
                            self-judgement."
            >
                <HelpOutlineIcon style={{ color: 'aliceblue' }} />
            </Tooltip> */}
          </div>
          <div style={{padding: "10px"}}>
            <CustomField
              value={habitResult}
              onChange={setHabitResult}
              label={"Result"}
              color={"aliceblue"}
              className={"habit-fields"}
              onEnter={handleKeyPress}
            />
            {/* <Tooltip title="The RESULT is how you feel
                                                after the behavior. In the
                                                short term, this might feel
                                                good, but in the long term,
                                                not as much."
            >
                <HelpOutlineIcon style={{ color: 'aliceblue' }} />
            </Tooltip> */}
          </div>
          <Box className='habit-card' style={{ justifyContent: "center", display: "inline-block" }}>
          <div style={{paddingTop: "5px", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Stack style={{ width: "100%" }} direction="row" spacing={2}>
                <CustomButton 
                    text={savedLoopSuccess ? 'Saved!' : 'Save'}
                    onClick={handleSaveNewLoop}
                    loading={savingLoop}
                    success={savedLoopSuccess}
                />
                <CustomButton
                    text={loopVis ? 'Hide' : 'Show'}
                    onClick={toggleLoopVis}
                    // onClick={() => navigate('/loops')}
                />
                <CustomButton 
                    text={"Logout"}
                    onClick={handleLogout}
                />
            </Stack>
          </div>
          </Box>
          {loopVis && (
            <div id="loops">
              <div style={{paddingTop: "35px"}}>
                <hr className='habit-card' color="#6B7E8E"></hr>
              </div>
              <div style={{ paddingTop: "20px" }}>
                <span style={{ fontSize: "calc(80% + 3.5vw)", color: "#6B7E8E" }}><b>My Habit Loops</b></span>
                <div style={{ paddingTop: "10px" }}>
                    <SavedHabits habits={habits} />
                </div>
              </div>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "center", paddingBottom: "20px", paddingTop: "15px" }}>
            <div className='habit-card'>
              <CustomButton
                  text={'Log some gratitude'}
                  onClick={() => navigate('/gratitude')}
              />
            </div>
          </div>
      </Box>

      <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={errorOpen}
          onClose={handleClose}
      >
          <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
          >
          {errorMessage}
          </Alert>
      </Snackbar>
    </div>
    </>
  );
}
