import { Client, Account } from 'appwrite';

const client = new Client()
  .setEndpoint('https://sm-b1.smorin.app/v1')
  .setProject('660820ca455a28164017');

const account = new Account(client);

const checkAuthentication = async () => {
  try {
    const response = await account.get();

    if (response.status === true) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error:', error.message);
    return false;
  }
};

export { checkAuthentication };
