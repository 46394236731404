import '../App.css';
import React, { useState, useEffect } from "react";
import SavedHabits from "../components/larger/SavedHabits";
import Header from '../components/larger/Header';
import { Client, Account, Databases, ID } from 'appwrite';
import { Box, Stack } from '@mui/material';
import CustomButton from '../components/pieces/CustomButton';
import { useNavigate } from "react-router-dom";

const client = new Client();
client
    .setEndpoint('https://sm-b1.smorin.app/v1')
    .setProject('660820ca455a28164017');
const account = new Account(client);
const databases = new Databases(client);

export default function Loops () {
    const navigate = useNavigate();
    const [habits, setHabits] = useState();

    useEffect(() => {
        const getHabits = async () => {
            try {
              const result = await databases.listDocuments(
                'awp-webdb1', // databaseId
                'sam-habit-loops', // collectionId
              );
              console.log('what the huck')
              console.log(result);
              setHabits(result.documents);
            } catch (error) {
              console.log('what the fuck')
              console.log(error.message);
            }
          };
    
        getHabits();
    },[])

    const handleLogout = async () => {
        try {
          const result = await account.deleteSession('current');
          console.log(result)
        } catch (error) {
          console.log(error.message)
        } finally {
          window.location.href = '/'
        }
    }

    return (
        <div className="App" style={{ display: 'flex', justifyContent: 'center', height: '100vh', width: "100vw" }}>
            <Box sx={{ width: "100vw" }}>
                <Header />
                <Box className='habit-card' style={{ justifyContent: "center", display: "inline-block" }}>
                    <div style={{paddingTop: "5px", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Stack style={{ width: "100%" }} direction="row" spacing={2}>
                            <CustomButton
                                text={'Go Back'}
                                onClick={() => navigate('/main')}
                            />
                            <CustomButton 
                                text={"Logout"}
                                onClick={handleLogout}
                            />
                        </Stack>
                    </div>
                </Box>
                <div style={{ paddingTop: "20px" }}>
                    <span style={{ fontSize: "24px" }}>Saved Habit Loops</span>
                    <div style={{ paddingTop: "10px" }}>
                        {habits && <SavedHabits habits={habits} />}
                    </div>
                </div>
            </Box>
        </div>
   )
}