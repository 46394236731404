import React from "react";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckIcon from '@mui/icons-material/Check';

export default function CustomButton ({ variant, text, onClick, loading, disabled, success, backgroundColor }) {

    const BootstrapButton = styled(Button)({
        width: '100%',
        height: '40px',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 17,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        color: '#282c34',
        backgroundColor: backgroundColor ? backgroundColor : 'aliceblue',
        borderColor: backgroundColor ? backgroundColor : 'aliceblue',
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
          backgroundColor: '#FFA8BE',
          borderColor: '#FFA8BE',
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
          backgroundColor: '#FFA8BE',
          borderColor: '#FFA8BE',
        },
        '&:focus': {
          boxShadow: '0 0 0 0.2rem #FFA8BE',
        },
        '&:disabled': {
          backgroundColor: "#6B7E8E",
          color: "#282c34",
          borderColor: "#6B7E8E",
        },
    });

    return (
      <BootstrapButton onClick={onClick} variant={variant ? variant : 'contained'} disableRipple disabled={disabled ? disabled : false}>
        {text} {loading ? (
          <span style={{ paddingLeft: "7px", marginTop: "6px" }}><CircularProgress size={20} thickness={6} style={{ color: "#282C34" }} /></span>
        ) : success ? (
          <span style={{ paddingLeft: "5px", marginTop: "6px" }}><CheckIcon style={{fontSize: "26px"}} /></span>
        ) : ('')}
      </BootstrapButton>
    )
}