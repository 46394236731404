import React from 'react';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

export default function CustomField({ label, variant, color, type, onChange, onEnter, width, autoFocus, className, value, id, multiline }) {
  const outerTheme = useTheme();

  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !multiline) {
      onEnter();
    }
  };

  const customTheme = (outerTheme) =>
    createTheme({
      palette: {
        mode: outerTheme.palette.mode,
      },
      components: {
        MuiTextField: {
          styleOverrides: {
            root: {
              '--TextField-brandBorderColor': 'aliceblue',
              '--TextField-brandBorderHoverColor': 'aliceblue',
              '--TextField-brandBorderFocusedColor': 'aliceblue',
              '& label.Mui-focused': {
                color: 'aliceblue',
              },
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderColor: 'aliceblue',
            },
            root: {
              [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: 'aliceblue',
              },
              [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                borderColor: '#FFA8BE',
              },
            },
          },
        },
        MuiFilledInput: {
          styleOverrides: {
            root: {
              '&::before, &::after': {
                borderBottom: '2px solid var(--TextField-brandBorderColor)',
              },
              '&:hover:not(.Mui-disabled, .Mui-error):before': {
                borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
              },
              '&.Mui-focused:after': {
                borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
              },
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            root: {
              '&::before': {
                borderBottom: '2px solid var(--TextField-brandBorderColor)',
              },
              '&:hover:not(.Mui-disabled, .Mui-error):before': {
                borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
              },
              '&.Mui-focused:after': {
                borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
              },
            },
          },
        },
      },
    });

    return (
        <ThemeProvider theme={customTheme(outerTheme)}>
            <TextField 
              id={id ? id : 'outlined-basic'}
              value={value && value}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              multiline={multiline ? multiline : false}
              // id="outlined-basic" 
              label={label}
              variant={variant ? variant : 'outlined'}
              InputProps={{
                style: {
                  color: color
                }
              }}
              InputLabelProps={{
                style: {
                  color: color
                } 
              }}
              style={{width: `${width && width}`}}
              type={type ? type : 'text'}
              autoComplete='off'
              autoFocus={autoFocus}
              className={className ? className : ''}
            />
        </ThemeProvider>
    )
}