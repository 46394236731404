import React from "react";
import Header from "../components/larger/Header";
import { Box } from "@mui/material";

export default function DontDoIt () {
    return (
        <div className="App" style={{ display: 'flex', justifyContent: 'center', height: '100vh', width: "100vw" }}>
            <Box sx={{ width: "100vw" }}>
                <Header />
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="habit-card" style={{ justifyContent: "center" }}>
                        <div style={{paddingTop: "10px"}}>
                            <hr className='habit-card' color="#6B7E8E"></hr>
                        </div>
                        <span style={{ fontSize: "calc(50% + 3.0vw)", color: "#6B7E8E" }}><b>Eating Fast Food</b></span>      
                        <p>
                            <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Physical Health</span>
                            <br/>
                            Avoiding fast food can lead to better physical health by reducing the intake of unhealthy fats, sugars, and excess calories associated with many fast food items. This can help in maintaining a healthy weight, reducing the risk of obesity, and preventing various health problems such as heart disease, diabetes, and high blood pressure.
                            <br/>
                            <br/>
                            <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Nutritional Balance</span>
                            <br/>
                            Fast food often lacks essential nutrients such as vitamins, minerals, and fiber, which are important for overall health. Choosing to avoid fast food allows individuals to prioritize nutritionally balanced meals with a greater variety of fruits, vegetables, lean proteins, and whole grains.
                            <br/>
                            <br/>
                            <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Energy Levels</span>
                            <br/>
                            Fast food high in sugar and unhealthy fats can lead to energy crashes and feelings of fatigue. Opting for healthier food choices can help maintain steady energy levels throughout the day, promoting better productivity and overall well-being.
                            <br/>
                            <br/>
                            <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Digestive Health</span>
                            <br/>
                            Regular consumption of fast food can disrupt digestive health, leading to issues such as indigestion, bloating, and constipation. Avoiding fast food allows for a healthier digestive system and can reduce the risk of gastrointestinal problems.
                            <br/>
                            <br/>
                            <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Financial Savings</span>
                            <br/>
                            Cutting out fast food can result in significant financial savings over time, as frequent visits to fast food restaurants can add up quickly. Cooking meals at home or choosing healthier dining options can be more cost-effective in the long run.
                            <br/>
                            <br/>
                            <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Improved Mood</span>
                            <br/>
                            A diet high in fast food has been linked to mood swings and increased risk of depression. Choosing nutritious, whole foods can positively impact mood and mental well-being.
                            <br/>
                            <br/>
                            <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Long-term Health</span>
                            <br/>
                            Avoiding fast food supports long-term health goals and can contribute to a higher quality of life as individuals age. It reduces the risk of developing chronic health conditions and promotes overall longevity.
                            <br/>
                            <br/>
                            <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Environmental Impact</span>
                            <br/>
                            Fast food production often involves unsustainable practices that contribute to environmental degradation, such as deforestation, greenhouse gas emissions, and excessive waste generation. Opting for more sustainable food choices can help reduce one's environmental footprint.
                            <br/>
                            <br/>
                            <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Cultural Exploration</span>
                            <br/>
                            Avoiding fast food encourages exploration of diverse cuisines and cooking methods from different cultures, promoting culinary diversity and appreciation for global flavors.
                            <br/>
                            <br/>
                            <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Cooking Skills</span>
                            <br/>
                            Choosing to cook meals at home instead of relying on fast food can improve cooking skills and foster a greater sense of connection with food preparation and nutrition. It can also be a rewarding and enjoyable activity for individuals and families alike.
                        </p>

                        <div style={{paddingTop: "10px"}}>
                            <hr className='habit-card' color="#6B7E8E"></hr>
                        </div>
                        <span style={{ fontSize: "calc(50% + 3.0vw)", color: "#6B7E8E" }}><b>Drinking Alcohol</b></span>
                        <br/>
                        <p>
                        <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Physical Health</span>
                        <br/>
                        Avoiding alcohol can lead to better physical health, including improved liver function, reduced risk of developing certain cancers (such as liver, mouth, throat, and breast cancer), and better heart health.
                        <br/>
                        <br/>
                        <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Mental Clarity</span>
                        <br/>
                        Sobriety can result in improved mental clarity and cognitive function, allowing for better decision-making and overall mental well-being.
                        <br/>
                        <br/>
                        <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Emotional Stability</span>
                        <br/>
                        Alcohol consumption can exacerbate mood swings and contribute to emotional instability. Sobriety can lead to greater emotional resilience and stability.
                        <br/>
                        <br/>
                        <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Financial Savings</span>
                        <br/>
                        Cutting out alcohol can result in significant financial savings over time, as alcohol consumption can be expensive, especially if it becomes a regular habit.
                        <br/>
                        <br/>
                        <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Improved Relationships</span>
                        <br/>
                        Sobriety can lead to healthier relationships with family, friends, and romantic partners, as alcohol consumption can sometimes strain or damage these relationships.
                        <br/>
                        <br/>
                        <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Reduced Risk of Addiction</span>
                        <br/>
                        Avoiding alcohol reduces the risk of developing alcohol addiction or dependence, which can have devastating effects on one's life and relationships.
                        <br/>
                        <br/>
                        <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Better Sleep Quality</span>
                        <br/>
                        Alcohol can disrupt sleep patterns and contribute to poor sleep quality. Sobriety can lead to improved sleep patterns and overall better rest.
                        <br/>
                        <br/>
                        <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Increased Productivity</span>
                        <br/>
                        Sobriety often leads to increased productivity and effectiveness in various aspects of life, including work, hobbies, and personal goals.
                        <br/>
                        <br/>
                        <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Legal and Safety Concerns</span>
                        <br/>
                        Abstaining from alcohol reduces the risk of engaging in risky behaviors such as drunk driving, which can have legal consequences and pose a danger to oneself and others.
                        <br/>
                        <br/>
                        <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Personal Growth</span>
                        <br/>
                        Choosing sobriety can be an opportunity for personal growth and self-improvement, allowing individuals to focus on their goals and aspirations without the hindrance of alcohol.
                        </p>

                        <div style={{paddingTop: "10px"}}>
                            <hr className='habit-card' color="#6B7E8E"></hr>
                        </div>
                        <span style={{ fontSize: "calc(50% + 3.0vw)", color: "#6B7E8E" }}><b>Marijuana Use</b></span>      
                        <p>
                            <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Physical Health</span>
                            <br/>
                            Avoiding marijuana can lead to better respiratory health, as smoking cannabis can irritate the lungs and lead to respiratory issues. Additionally, abstaining from marijuana can promote better overall physical health and reduce the risk of certain health problems associated with long-term use.
                            <br/>
                            <br/>
                            <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Mental Clarity</span>
                            <br/>
                            Sobriety from marijuana can result in improved mental clarity, memory, and cognitive function, allowing for better decision-making and overall mental well-being.
                            <br/>
                            <br/>
                            <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Emotional Stability</span>
                            <br/>
                            Marijuana use can sometimes exacerbate mood swings and contribute to emotional instability, particularly in heavy or frequent users. Sobriety can lead to greater emotional resilience and stability.
                            <br/>
                            <br/>
                            <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Financial Savings</span>
                            <br/>
                            Cutting out marijuana can result in significant financial savings over time, as purchasing cannabis products can be expensive, especially if it becomes a regular habit.
                            <br/>
                            <br/>
                            <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Improved Relationships</span>
                            <br/>
                            Sobriety can lead to healthier relationships with family, friends, and romantic partners, as excessive marijuana use can sometimes strain or damage these relationships.
                            <br/>
                            <br/>
                            <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Reduced Risk of Addiction</span>
                            <br/>
                            Avoiding marijuana reduces the risk of developing cannabis addiction or dependence, which can have negative effects on one's life and relationships.
                            <br/>
                            <br/>
                            <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Better Sleep Quality</span>
                            <br/>
                            Chronic marijuana use can disrupt sleep patterns and contribute to poor sleep quality. Sobriety can lead to improved sleep patterns and overall better rest.
                            <br/>
                            <br/>
                            <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Increased Productivity</span>
                            <br/>
                            Sobriety often leads to increased productivity and effectiveness in various aspects of life, including work, hobbies, and personal goals.
                            <br/>
                            <br/>
                            <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Legal and Safety Concerns</span>
                            <br/>
                            Abstaining from marijuana reduces the risk of legal consequences associated with its use, particularly in regions where it is not legal or where there are restrictions on its use. Additionally, marijuana use can impair judgment and coordination, posing safety risks in certain situations.
                            <br/>
                            <br/>
                            <span style={{ fontSize: "calc(50% + 2.0vw)", color: "#6B7E8E" }}>Personal Growth</span>
                            <br/>
                            Choosing sobriety from marijuana can be an opportunity for personal growth and self-improvement, allowing individuals to focus on their goals and aspirations without the influence of the drug.
                        </p>
                    </div>
                </div>
            </Box>
        </div>
    )
}