import React, { useEffect, useState } from "react";
import CustomField from "../pieces/CustomField";
import GratitudeCard from "../pieces/GratitudeCard";

export default function SavedGratitudes({ gratitudes }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredGratitudes, setFilteredGratitudes] = useState([]);
  
  const reversedGratitudes = gratitudes.slice().reverse();

  useEffect(() => {
    console.log(gratitudes);
    filterGratitudes();
  }, [gratitudes, searchTerm]);

  const filterGratitudes = () => {
    if (!searchTerm) {
      setFilteredGratitudes(reversedGratitudes);
    } else {
      const filtered = reversedGratitudes.filter((gratitude) =>
        gratitude.gratitude.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredGratitudes(filtered);
    }
  };

  return (
    <div style={{ paddingBottom: "40px" }}>
      {!gratitudes.length ? (
        <>
            <div style={{ padding: '20px' }}>
                <i>You don't have any gratitudes stored yet!</i>
            </div>
        </>
      ) : (
        <CustomField
            label={"Filter"}
            color={"aliceblue"}
            onChange={setSearchTerm}
            value={searchTerm}
            className={"habit-fields"}
        />
      )}
      {filteredGratitudes.map((gratitude, index) => (
        <GratitudeCard
          gratitude={gratitude.gratitude}
          date={gratitude.date}
          index={index}
          key={index}
          id={gratitude.$id}
        />
      ))}
    </div>
  );
}
