import React, { useState } from "react";
import '../../App.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import logo from '../../img/brain.png'
import CustomButton from "../pieces/CustomButton";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

export default function Header () {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    return (
      <>
        <header className="App-header">
          {/* <div className="habit-card"> */}
            {/* <Grid container spacing={0}> */}
              {/* <Grid item xs={4}> */}
                <div style={{cursor: "pointer"}} onClick={handleClickOpen}>
                  <img src={logo} className="App-logo" alt="logo" />
                </div>
              {/* </Grid> */}
              {/* <Grid item xs={8}> */}
                <div>
                  <span style={{fontSize: "32px"}}><b>AwarePower</b></span>
                </div>
                <div>
                  <span style={{fontSize: "18px"}}>"It's not you, it's your brain!"</span>
                </div>
                {/* <div>
                  <span style={{fontSize: "14px"}}><i><a href='https://www.faraday.cam.ac.uk/wp-content/uploads/resources/Faraday%20Papers/Faraday%20Paper%2017%20Clarke_EN.pdf' target='_blank'>Willpower is a myth.</a> — <a href='https://pure.psu.edu/en/publications/habits-awareness-and-autonomy' target='_blank'>Awareness isn't.</a></i></span>
                </div> */}
              {/* </Grid> */}
            {/* </Grid> */}
          {/* </div> */}
        </header>
    
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{fontSize: "32px", color: "aliceblue"}} id="alert-dialog-title">
            <span style={{ color: "#282C34" }}><b>Awareness is the key</b></span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            Recognizing our existing bad habits is like turning on a spotlight in the dim room of our subconscious. It's the spark that ignites the fire of change, casting light on our understanding, fueling our motivation, and empowering us to take decisive action.
            <br/>
            <br/>
            As we uncover these detrimental behaviors, it's akin to embarking on a journey of self-discovery. We delve into the depths of our psyche, peeling back the layers to reveal the specific habits that stand as roadblocks to our progress or well-being. This revelation isn't merely an observation; it's a call to arms, urging us to take ownership of our actions and reclaim control over our lives.
            <br/>
            <br/>
            But awareness offers more than just a mirror reflecting our flaws. It's a compass guiding us through the maze of our motivations and triggers, unveiling the tangled roots of our habits. Armed with this knowledge, we can craft targeted strategies to untangle ourselves from negative patterns and embrace healthier alternatives.
            <br/>
            <br/>
            Through the lens of awareness, we gain clarity on our path forward. It's not just about setting lofty goals; it's about charting a course with purpose, monitoring our progress along the way, and enlisting the support of others on similar journeys. And woven into this tapestry of change is the thread of mindfulness, anchoring us in the present moment as we strive for lasting transformation.
            <br/>
            <br/>
            So let's embrace awareness as the cornerstone of our change, not just as a fleeting revelation, but as a steadfast companion on our quest for a better, brighter tomorrow.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <CustomButton
              text={
                // <span style={{ color: "aliceblue" }}>Close</span>
                <span>Close</span>
              }
              onClick={handleClose}
              // backgroundColor={'#282C34'} 
            />
            <CustomButton
              // backgroundColor={'#282C34'} 
              text={"Read More"}
              onClick={() => window.open('https://www.checkable.com/blogs/blog/the-importance-of-tracking-habits', '_blank')}
            />
          </DialogActions>
        </Dialog>
      </>
    )
}