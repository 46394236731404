import React, { useState, useEffect } from "react";
// import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Route, Routes, Navigate } from 'react-router-dom';
import Main from "./pages/Main";
import Login from "./pages/Login";
import { checkAuthentication } from "./components/authUtils/authUtils";
// import Navbar from "./components/larger/Navbar";
import Loops from "./pages/Loops";
import Gratitude from "./pages/Gratitude";
import DontDoIt from "./pages/DontDoIt";

function App() {
  const [authInProgress, setAuthInProgress] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const isAuthenticated = await checkAuthentication();
        setAuthenticated(isAuthenticated);
      } finally {
        setAuthInProgress(false);
      }
    };

    checkAuthStatus();
  }, []);

  const handleAuthentication = (status) => {
    setAuthenticated(status);
  };

  if (authInProgress) {
    console.log('authInProgress')
    return null;
  }

  return (
    <Routes>
      <Route path="/" element={<Login setAuthentication={handleAuthentication} isAuthed={authenticated} />} />
      {authenticated && (
        <>
          <Route path="/main" element={<Main />} />
          <Route path="/loops" element={<Loops />} />
          <Route path="/gratitude" element={<Gratitude />} />
          <Route path="/drawbacks" element={<DontDoIt />} />
        </>
      )}
      <Route
        path="*"
        element={<Navigate to="/" replace />}
      />
    </Routes>
  );
}

export default App;