import React, { useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { Client, Databases } from "appwrite";
import { CircularProgress, Divider } from "@mui/material";

const client = new Client();
client
    .setEndpoint('https://sm-b1.smorin.app/v1')
    .setProject('660820ca455a28164017');
const databases = new Databases(client);

export default function HabitCard ({ trigger, behavior, result, date, index, id }) {
    const [deletingHabit, setDeletingHabit] = useState();

    function formatDate(inputDate) {
        const date = new Date(inputDate);
      
        const daysOfWeek = [
          'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
        ];
      
        const months = [
          'January', 'February', 'March', 'April', 'May', 'June', 
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
      
        const dayOfWeek = daysOfWeek[date.getDay()];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const period = hours >= 12 ? 'pm' : 'am';
        const formattedHours = hours % 12 || 12;

        const formattedDate = `${dayOfWeek} ${month} ${day}${getDaySuffix(day)}, ${year} at ${formattedHours}:${minutes}${period}`;
      
        return formattedDate;
    }
      
    function getDaySuffix(day) {
        if (day >= 11 && day <= 13) {
          return 'th';
        }
        switch (day % 10) {
          case 1: return 'st';
          case 2: return 'nd';
          case 3: return 'rd';
          default: return 'th';
        }
    }

    const deleteHabit = async (id) => {
        console.log(id)
        setDeletingHabit(true);
        try {
            const result = await databases.deleteDocument(
                'awp-webdb1', // databaseId
                'sam-habit-loops', // collectionId
                id
            )
            console.log(result)
        } catch (error) {
            console.log(error)
        } finally {
            setDeletingHabit(false);
            window.location.href = "/"
        }
    }
      
    return (
        <div>
            <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                <span style={{ color: "#6B7E8E" }}>{formatDate(date)}</span>
                <span>
                <span onClick={() => deleteHabit(id)} style={{ cursor: "pointer" }}>
                    <span style={{ position: "absolute", marginTop: "-2.5px", paddingLeft: "5px" }}>
                    {deletingHabit ? (
                        <CircularProgress size={20} thickness={5} style={{ color: "#6B7E8E" }} />
                    ) : (
                        <DeleteForeverIcon sx={{ color: "#6B7E8E" }} />
                    )}
                    </span>
                </span>
                </span>
            </div>
            <div id={index} style={{ display: 'flex', justifyContent: 'center', paddingBottom: "10px" }}>
                <div className="habit-card" style={{ backgroundColor: "#6b7f8f", height: "100%", borderRadius: "5px", color: "black", paddingBottom: "7px", paddingLeft: "4px", paddingRight: "4px" }}>
                    <Grid sx={{paddingTop: "5px"}} container spacing={2}>
                        <Grid item xs={4}>
                            <span style={{color: "#282C34"}}><i>Trigger:</i></span>
                            <br/>
                            <span style={{color: "#282C34", fontSize: "calc(50% + 2vw)"}}><b>{trigger ? trigger : <i>not defined</i>}</b></span> 
                        </Grid>
                        <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} color="#282C34" />
                        <Grid item xs={4}>
                            <span style={{color: "#282C34"}}><i>Behavior:</i></span>
                            <br/>
                            <span style={{color: "#282C34", fontSize: "calc(50% + 2vw)"}}><b>{behavior}</b></span> 
                        </Grid>
                        <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} color="#282C34" />
                        <Grid item xs={4}>
                            <span style={{color: "#282C34"}}><i>Result:</i></span>
                            <br/>
                            <span style={{color: "#282C34", fontSize: "calc(50% + 2vw)"}}><b>{result}</b></span> 
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}