import '../App.css';
import React, { useEffect, useState } from "react";
import Header from "../components/larger/Header";
import { Box, Stack } from '@mui/material';
import CustomField from '../components/pieces/CustomField';
import SavedGratitudes from '../components/larger/SavedGratitudes';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import useLocalStorage from '../components/utils/useLocalStorage';
import { useNavigate } from "react-router-dom"; 

import { Client, Account, Databases, ID } from 'appwrite';
import CustomButton from '../components/pieces/CustomButton';
const client = new Client();
client
    .setEndpoint('https://sm-b1.smorin.app/v1')
    .setProject('660820ca455a28164017');
const account = new Account(client);
const databases = new Databases(client);

export default function Gratitude () {
    const navigate = useNavigate();
    const [gratitude, setGratitude] = useState();
    const [savedGratitudes, setSavedGratitudes] = useState();
    const [savedGratitudeSuccess, setSavedGratitudeSuccess] = useState();
    const [savingGratitude, setSavingGratitude] = useState();

    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [gratitudeVis, setGratitudeVis] = useLocalStorage('gratitudeVis', false);

    useEffect(() => {
        const getGratitudes = async () => {
            try {
              const result = await databases.listDocuments(
                'awp-webdb1', // databaseId
                'saved-gratitudes', // collectionId
              );
              console.log(result);
              setSavedGratitudes(result.documents);
            } catch (error) {
              console.log(error.message);
            }
          };
    
        getGratitudes();
      },[])
      const getGratitudes = async () => {
        try {
          const result = await databases.listDocuments(
            'awp-webdb1', // databaseId
            'saved-gratitudes', // collectionId
          );
          console.log(result);
          setSavedGratitudes(result.documents);
        } catch (error) {
          console.log(error.message);
        }
      };

      const handleLogout = async () => {
        try {
          const result = await account.deleteSession('current');
          console.log(result)
        } catch (error) {
          console.log(error.message)
        } finally {
          window.location.href = '/'
        }
      }

      const handleClose = () => {
        setErrorOpen(false);
      }

      const handleSaveNewGratitude = async () => {
        setSavingGratitude(true);
    
        if (gratitude === '') {
            setErrorMessage('Invalid document structure: Missing required attribute "gratitude"')
            setErrorOpen(true)
            setTimeout(() => setErrorOpen(false),5000)
        }
    
        const data = {
          gratitude: gratitude,
          date: new Date()
        };
        
        try {
            const result = await databases.createDocument(
                'awp-webdb1', // databaseId
                'saved-gratitudes', // collectionId
                ID.unique(), // documentId
                data,
              );
            console.log(result)
            if (result) {
                setSavedGratitudeSuccess(true)
                setTimeout(() => setSavedGratitudeSuccess(),5000)
                getGratitudes();
            }
            // setTrigger('');
            // setBehavior('');
            // setHabitResult('');
        } catch (error) {
            console.log(error.message)
            setErrorMessage(error.message)
            setErrorOpen(true);
            setTimeout(() => setErrorOpen(false),5000)
        } finally {
            setSavingGratitude(false)
            setGratitude(null);
            document.getElementById('gratitude-field').focus()
        }
      }

      const toggleGratitudeVis = () => {
        if (gratitudeVis) {
          setGratitudeVis(false)
          document.getElementById('gratitude-div').style.alignItems = 'center'
          // document.getElementById('loops').style.display = 'none'
        } else {
          setGratitudeVis(true)
          document.getElementById('gratitude-div').style.alignItems = ''
          // document.getElementById('loops').style.display = 'block'
        }
      }

      useEffect(() => {
        if (!gratitudeVis) {
          setGratitudeVis(false)
          document.getElementById('gratitude-div').style.alignItems = 'center'
          // document.getElementById('loops').style.display = 'none'
        } else {
          setGratitudeVis(true)
          document.getElementById('gratitude-div').style.alignItems = ''
          // document.getElementById('loops').style.display = 'block'
        }
      },[])

    return (
        <div className="App" id="gratitude-div" style={{ display: 'flex', justifyContent: 'center', alignItems: '', height: '100vh', width: "100vw" }}>
            <Box sx={{ width: "100vw" }}>
                <Header />
                <div style={{paddingBottom: "10px"}}>
                    <hr className='habit-card' color="#6B7E8E"></hr>
                </div>
                <div style={{ paddingBottom: "20px" }}>
                    <span style={{ fontSize: "calc(80% + 3.5vw)", color: "#6B7E8E" }}><b>Gratitude</b></span>
                    <br/>
                    <span>Stop, look around, and then express some fkn gratitude.</span>
                </div>
                <CustomField
                    id="gratitude-field"
                    value={gratitude}
                    onChange={setGratitude}
                    label={"What are you thankful for?"}
                    color={"aliceblue"}
                    className={"habit-fields"}
                    multiline
                />
                {/* <div style={{ justifyContent: 'center', display: 'flex', paddingTop: "10px" }}>
                    <div className='habit-card'>
                        <CustomButton 
                            text={savedGratitudeSuccess ? 'Saved!' : 'Save'}
                            onClick={handleSaveNewGratitude}
                            loading={savingGratitude}
                            success={savedGratitudeSuccess}
                        />
                    </div>
                </div> */}
                <div style={{ paddingTop: "10px" }}></div>
                <Box className='habit-card' style={{ justifyContent: "center", display: "inline-block" }}>
                  <div style={{paddingTop: "5px", display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Stack style={{ width: "100%" }} direction="row" spacing={2}>
                        <CustomButton 
                            text={savedGratitudeSuccess ? 'Saved!' : 'Save'}
                            onClick={handleSaveNewGratitude}
                            loading={savingGratitude}
                            success={savedGratitudeSuccess}
                        />
                        <CustomButton
                            text={gratitudeVis ? 'Hide' : 'Show'}
                            onClick={toggleGratitudeVis}
                            // onClick={() => navigate('/loops')}
                        />
                        <CustomButton 
                            text={"Logout"}
                            onClick={handleLogout}
                        />
                    </Stack>
                  </div>
                </Box>
                {gratitudeVis && (
                  <div style={{ paddingTop: "10px" }}>
                    <div style={{paddingTop: "10px"}}>
                        <hr className='habit-card' color="#6B7E8E"></hr>
                    </div>
                    <span style={{ fontSize: "calc(80% + 3.5vw)", color: "#6B7E8E" }}><b>Gratitude Log</b></span>
                    <div style={{ paddingTop: "10px" }}>
                        {savedGratitudes ? <SavedGratitudes gratitudes={savedGratitudes} /> : <span>nothign here !</span>}
                    </div>
                  </div>
                )}
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '15px', paddingBottom: '20px' }}>
                  <div className='habit-card'>
                    <CustomButton
                        text={'Go Back'}
                        onClick={() => navigate(-1)}
                    />
                  </div>
                </div>
            </Box>

            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={errorOpen}
                onClose={handleClose}
            >
                <Alert
                  onClose={handleClose}
                  severity="error"
                  variant="filled"
                  sx={{ width: '100%' }}
                >
                  {errorMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}