import React, { useState, useEffect } from "react";
import { Client, Account } from 'appwrite';
import CustomField from "../components/pieces/CustomField";
import Box from '@mui/material/Box';
import CustomButton from "../components/pieces/CustomButton";
import Header from "../components/larger/Header";
import { useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const client = new Client();

client
    .setEndpoint('https://sm-b1.smorin.app/v1')
    .setProject('660820ca455a28164017');
    
const account = new Account(client);

export default function Login({ setAuthentication, isAuthed }) {
    const navigate = useNavigate();
    const [userName, setUserName] = useState();
    const [password, setPassword] = useState();
    const [loading, setLoading] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const handleLogin = async () => {
        setLoading(true)
        try {
            const response = await account.createEmailPasswordSession(userName, password);
            console.log(response)
            if (response.userId) {
                setAuthentication(true)
                navigate('/main')
            }
        } catch (error) {
            console.log(error.message)
            setErrorMessage(error.message)
            setErrorOpen(true)
            setTimeout(() => setErrorOpen(false),5000)
            setAuthentication(false)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        isAuthed && navigate('/main');
    },[])

    const handleKeyPress = () => {
        handleLogin();
    };

    const handleClose = () => {
        setErrorOpen(false);
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', color: 'aliceblue' }}>
            <Box>
                <Header />
                <div style={{padding: "10px"}}>
                    <CustomField
                        onChange={setUserName}
                        label={"Email"}
                        color={"aliceblue"}
                        onEnter={handleKeyPress}
                        autoFocus={true}
                        width={'300px'}
                    />
                </div>
                <div style={{padding: "10px"}}>
                    <CustomField
                        onChange={setPassword}
                        label={"Password"}
                        color={"aliceblue"}
                        type={"password"}
                        onEnter={handleKeyPress}
                        width={'300px'}
                    />
                </div>
                <div style={{padding: "10px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Stack style={{ width: "100%" }} direction="row" spacing={2}>
                      <CustomButton
                          loading={loading}
                          text={"Login"}
                          onClick={handleLogin}
                      />
                      <CustomButton
                        text={"Sign Up"}
                        onClick={handleLogin}
                        disabled={true}
                      />
                    </Stack>
                </div>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={errorOpen}
                onClose={handleClose}
                // message={errorMessage}
                // key={vertical + horizontal}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
              >
                {errorMessage}
              </Alert>
            </Snackbar>
        </div>
    )
}